import Vue from 'vue';
import Component from 'vue-class-component';
import {format, parseISO, subDays} from 'date-fns';

interface IDates {
  fromDate?: string,
  toDate?: string,
}

@Component
/**
 * Date Formating Mixin for methods to convert and display dates
 */
export default class DateFormat extends Vue {
  /**
   * Determines whether the date is a string and if so converts it to a date
   * @param {string | date} date
   * @return {date} returns a date
   */
  __MIXINparseDate(date) {
    return typeof date === 'string' ? parseISO(date) : date;
  }
  /**
   * Produced a date string that has the format '2021-09-05'
   * @param {string | date} date
   * @return {string} returns a date string formatted to Year Month Day
   */
  __MIXINformatDateYMD(date) {
    if (!date ) {
      date = new Date();
    }
    return format(this.__MIXINparseDate(date), 'yyyy-MM-dd');
  }
  /**
   * Returns the data string from one week ago
   * @return {string} returns a date object formatted to Year Month Day
   */
  __MIXINgetLastWeek() {
    return this.__MIXINformatDateYMD(subDays(new Date(), 6));
  }
  /**
   * Returns the data string from previous day
   * @param {string | date} date
   * @return {string} returns a date object formatted to Year Month Day
   */
  __MIXINgetPreviousDay(date: any): string {
    return this.__MIXINformatDateYMD(subDays(this.__MIXINparseDate(date), 1));
  }
  /**
   * Creates a dates object containing an optional fromDate and an optional toDate
   * @param {string | date} start
   * @param {string | date} end
   * @return {string} returns a dates object
   */
  __MIXINcreateDates(start, end): IDates {
    const dates: IDates = {};
    if (start) {
      dates.fromDate = this.__MIXINformatDateYMD(start);
    }
    if (end) {
      dates.toDate = this.__MIXINformatDateYMD(end);
    }
    return dates;
  }

  /**
   * Formats the date into wanted format
   * @param {string} date - the date from the API
   * @return {string} - the formatted date
   */
  __MIXINformatDateTime(date: string): string {
    const baseDate = this.__MIXINparseDate(date);
    return format(baseDate, 'dd/MM/yyyy HH:mm');
  }
}
