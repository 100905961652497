
import Component, {mixins} from 'vue-class-component';

import Gotcha from '@/components/Gotcha.vue';
import Plugins from '@/components/Plugins.vue';
import Select2 from 'v-select2-component';

import {format, startOfHour, add, set, isDate} from 'date-fns';
import DateFormat from '@/mixins/DateFormat';

import {userStore} from '@/store/__STORE_user';
import {meetingStore} from '@/store/__STORE_meetings';

export interface ITimezone {
  id: string;
  name: string;
  offset?: number;
}

@Component({
  components: {
    Gotcha,
    Plugins,
    Select2,
  },
})

/**
 * Intelligent component for Create Meeting, component allows for the creation of new meetings
 */
export default class CreateMeeting extends mixins(DateFormat) {
  name: 'Create Meeting';

  tipList: Array<string> = [
    'Create a scheduled call here by filling out the subject, date and time',
    'Send invites to your guest via your email provider or share in plain text by copy & pasting',
  ];
  isShowGotcha: boolean = true;
  subject: string = '';
  startDate: null | Date = null;
  startTime: string = '';
  endTime: string = '';
  errors: any = {};
  timeList: Array<any> = [];
  isManualStart: boolean = false;
  usePersonalRoom: boolean = false;
  select2Settings: any = {
    tags: true, // This allows custom times such as 17:05
    placeholder: 'Select time',
  };
  masks: any = {
    input: 'DD-MM-YYYY',
  };
  attributes: Array<any> = [];
  timezones: Array<ITimezone> = [];

  timeRegex = new RegExp(/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/);

  /**
   * Gets personal settings from the userStore
   */
  get meetingId(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null;
  }
  /**
   * Gets personal settings from the userStore
   */
  get settings() {
    return userStore.personalSettings;
  }
  /**
   * Gets selected meeting from the meetingStore
   */
  get selMeeting() {
    return meetingStore.selMeeting;
  }
  /**
   * Gets user profile from the userStore
   */
  get auth() {
    return userStore.profileData;
  }
  /**
   * Gets moment date
   * @return {date}
   */
  get formatDate(): string {
    return format(this.__MIXINparseDate(this.startDate), 'dd/MM/yyyy');
  }
  /**
   * Get the user's timezone from the userStore settings
   */
  get timezone(): ITimezone | null {
    let timezoneObj: ITimezone = null;
    if (
      this.settings &&
      this.settings.length > 0 &&
      this.timezones &&
      this.timezones.length > 0
    ) {
      const timezoneName = this.settings[
          Object.keys(this.settings).find(
              (key) => this.settings[key].key === 'timezone',
          )
      ].value;

      // Retrieve the timezone object from the timezones array
      if (timezoneName.length > 0) {
        timezoneObj = this.timezones[
            Object.keys(this.timezones).find(
                (key) => this.timezones[key].id === timezoneName,
            )
        ];
      }
    }
    return timezoneObj;
  }

  /**
   * Does the local system timezone match the users timezone
   */
  get isSetTimezoneLocal(): boolean {
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return this.timezone ? localTimezone === this.timezone.id : false;
  }

  /**
   * Initialise the timeList and get the meeting if an ID prop is passed
   */
  created() {
    /** Get timezones */
    meetingStore.getTimezones().then((response) => {
      this.timezones = response.data.map((timezoneData) => {
        const gmt =
          ' GMT' + (timezoneData.offset >= 0 ? '+' : '') + timezoneData.offset;
        return {
          id: timezoneData.identifier,
          name: timezoneData.identifier + gmt,
          offset: timezoneData.offset,
        };
      });
      this.initialiseMeetingData();
    });
  }

  /**
   * Initialises the meeting data
   */
  initialiseMeetingData(): void {
    this.timeList = [];
    for (let t = 0; t <= 1425; t += 15) {
      const h = Math.trunc(t / 60);
      const m = t % 60;
      const strH = h > 9 ? h : '0' + h;
      const strM = m > 9 ? m : '0' + m;
      this.timeList.push(strH + ':' + strM);
    }
    if (this.meetingId) {
      meetingStore
          .getMeeting(this.meetingId)
          .then(() => {
            this.subject = this.selMeeting.subject;

            const startsAt = this.__MIXINparseDate(this.selMeeting.starts_at);
            const endsAt = this.__MIXINparseDate(this.selMeeting.ends_at);

            this.startTime = format(startsAt, 'HH:mm');
            this.endTime = format(endsAt, 'HH:mm');

            this.addTime(this.startTime);
            this.addTime(this.endTime);

            this.startDate = this.selMeeting.starts_at;

            this.usePersonalRoom = this.selMeeting.use_personal_room;
          })
          .catch((err) => {});
    } else {
      this.startDate = new Date();

      this.startDate = add(
          this.updateWithTimezoneOffset(this.startDate),
          {hours: 1},
      );

      this.startTime = format(
          startOfHour(this.startDate),
          'HH:mm',
      );
      this.endTime = format(
          add(startOfHour(this.startDate), {minutes: 15}),
          'HH:mm',
      );
    }
  }

  /**
   * If the user's timezone is different from the system machine, adjust the date to reflect the user's timezone
   * Else we get weird things where you can't save the meeting time as it can be in the past
   * @param {Date} date
   * @return {Date}
   */
  updateWithTimezoneOffset(date: Date): Date {
    if (!this.isSetTimezoneLocal) {
      const localOffset = parseInt(format(this.startDate, 'X'));
      date = add(this.startDate, {hours: this.timezone.offset - localOffset});
    }
    return date;
  }

  /**
   * Add a start time
   * @param {string} val
   */
  addTimeStart(val): void {
    this.addTime(val);
    this.startTime = val;
  }

  /**
   * Add a end time
   * @param {string} val
   */
  addTimeEnd(val): void {
    this.addTime(val);
    this.endTime = val;
  }

  /**
   * Add a time to the timeList
   * @param {string} val
   */
  addTime(val): void {
    if (val.length === 5) {
      const findIndex = this.timeList.findIndex((element) => element === val);
      if (findIndex === -1) {
        this.timeList.push(val);
        this.timeList.sort();
      }
    }
  }

  /**
   * Validate time
   * @param {string} time
   * @return {boolean}
   */
  isValid(time): boolean {
    return this.timeRegex.test(time);
  }

  /**
   * Fired when a start time is selected
   * @param {object} value
   */
  onStartTimeSelected(value): void {
    const isValid = this.isValid(value.text);

    const startMoment = isValid ?
      set(this.__MIXINparseDate(this.startDate), {
        hours: parseInt(this.startTime.substring(0, 2)),
        minutes: parseInt(this.startTime.substring(3, 5)),
      }) :
      add(startOfHour(this.startDate), {hours: 1, minutes: 15});

    // Start Time
    const timeStart = format(startMoment, 'HH:mm');
    this.addTimeStart(timeStart);

    // End Time
    let timeEnd = format(add(startMoment, {minutes: 15}), 'HH:mm');
    const lastMinutes = [
      '23:45',
      '23:46',
      '23:47',
      '23:48',
      '23:49',
      '23:50',
      '23:51',
      '23:52',
      '23:53',
      '23:54',
      '23:55',
      '23:56',
      '23:57',
      '23:58',
      '23:59',
    ];
    if (lastMinutes.indexOf(timeStart) !== -1) {
      timeEnd = '23:59';
    }

    this.addTimeEnd(timeEnd);
  }

  /**
   * Fired when a start time is selected
   */
  saveMeeting(): void {
    this.errors = [];
    let flagError = false;
    if (!this.subject) {
      this.errors.subject = ['The subject field is required!'];
      flagError = true;
    }
    if (!this.startDate) {
      this.errors.start_date = ['The start date field is required!'];
      flagError = true;
    }
    if (!this.startTime) {
      this.errors.starts_at = ['The start time field is required!'];
      flagError = true;
    }
    if (!this.endTime) {
      this.errors.ends_at = ['The end time field is required!'];
      flagError = true;
    }
    if (flagError) return;

    const startsAt = set(this.__MIXINparseDate(this.startDate), {
      hours: parseInt(this.startTime.substring(0, 2)),
      minutes: parseInt(this.startTime.substring(3, 5)),
    });
    const endsAt = set(this.__MIXINparseDate(this.startDate), {
      hours: parseInt(this.endTime.substring(0, 2)),
      minutes: parseInt(this.endTime.substring(3, 5)),
    });

    if (!isDate(startsAt)) {
      this.errors.starts_at = ['The start time is not a valid date!'];
      flagError = true;
    }
    if (!isDate(endsAt)) {
      this.errors.ends_at = ['The end time is not a valid date!'];
      flagError = true;
    }
    if (flagError) return;

    if (this.meetingId) {
      const param = {
        id: this.meetingId,
        subject: this.subject,
        description: this.subject,
        starts_at: format(startsAt, 'yyyy-MM-dd HH:mm'),
        ends_at: format(endsAt, 'yyyy-MM-dd HH:mm'),
        use_personal_room: this.usePersonalRoom,
      };

      meetingStore
          .updateMeeting(param)
          .then(() => {
            this.$toast.success('Updated successfully!');
            this.errors = [];
            this.$router.push({
              name: 'meeting-updated',
              params: {id: this.meetingId.toString()},
            });
          })
          .catch((err) => {
            try {
              const data = err.response.data;
              this.$toast.error(data.message);

              if (err.response.status === 422) {
                this.errors = data.errors;
              }
            } catch {
              this.$toast.error(err);
            }
          });
    } else {
      let pin = '';
      let guestPin = '';
      this.settings.forEach((item) => {
        switch (item.key) {
          case 'pin':
            pin = item.value;
            break;
          case 'guest_pin':
            guestPin = item.value;
            break;
        }
      });

      const param = {
        pin: pin,
        guest_pin: guestPin,
        subject: this.subject,
        description: this.subject,
        starts_at: format(startsAt, 'yyyy-MM-dd HH:mm'),
        ends_at: format(endsAt, 'yyyy-MM-dd HH:mm'),
        use_personal_room: this.usePersonalRoom,
      };

      meetingStore
          .addMeeting(param)
          .then((response) => {
            this.$toast.success('The meeting is added successfully!');
            const meetingId = response.data.id;
            this.errors = [];
            this.$router.push({
              name: 'meeting-invites',
              params: {id: meetingId},
            });
          })
          .catch((err) => {
            try {
              const data = err.response.data;
              this.$toast.error(data.message);

              if (err.response.status === 422) {
                this.errors = data.errors;
              }
            } catch {
              this.$toast.error(err);
            }
          });
    }
  }
}
