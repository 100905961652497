<template>
  <div class="call-to-action">
    <div class="row align-items-center">
      <div class="col-xl-7">
        <div class="call-to-action-content">
          <h4>Create meetings now!</h4>
          <p>Plugins for launching and creating meetings in 3rd party software are coming soon</p>
        </div>
      </div>
      <div class="col-xl-5 mt-1 mt-xl-0">
        <div class="row">
          <div class="col-sm-6">
            <div class="call-to-action-option">
              <div class="icon">
                <img
                  alt="Download for Google Chrome"
                  src="@/assets/img/icons/icon-chrome.svg"
                >
              </div>
              <div class="content">
                <p>Google Chrome</p>
                <!--
                <a href="#">Download</a>
                -->
              </div>
            </div>
          </div>
          <div class="col-sm-6 mt-1 mt-sm-0">
            <div class="call-to-action-option">
              <div class="icon">
                <img
                  alt="Download for Microsoft Outlook"
                  src="@/assets/img/icons/icon-outlook.svg"
                >
              </div>
              <div class="content">
                <p>Microsoft Outlook</p>
                <!--
                <a href="#">Download</a>
                -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {
  },
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
  .call-to-action-content {
    h4 {
      font-size: 2.1rem;
      letter-spacing: -0.6px;
      margin-bottom: 0.5rem;
    }
    p {
      letter-spacing: -0.2px;
      line-height: 1.275;
      margin-bottom: 0;
    }
  }

  .call-to-action-option {
    align-items: center;
    display: flex;
    .icon {
      margin-right: 1.25rem;
      & img {
        height: 2rem;
      }
    }
    .content {
      flex: 1;
      p {
        font-weight: 600;
        letter-spacing: -0.2px;
        margin-bottom: 0;
      }
    }
  }
</style>
